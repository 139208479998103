.Landing {
  text-align: left;
  background-image: url(./pictures/landingPage.jpg);
  position: relative;
  min-width: 100%;
  width:auto;
  height:100vh;
  flex-direction: column;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  padding-bottom: 50px;
  margin-bottom: 50px;
  overflow-y: scroll;
  display: flex;
  /* overflow-x: scroll; */
  /* min-height: 100%; */
  /* width: 100vh; */
  /* height:auto; */
  /* background-size: contain; */
  /* background-color: #0659b5; */
  /* background-image:linear-gradient(to right,#0146ab , #189cdd); */
  /* margin:auto; */
}

.GuestDashboard {
  text-align: left;
  background-image: url(./pictures/guestDashboard.jpg);
  position: relative;
  min-width: 100%;
  width:auto;
  height:100vh;
  flex-direction: column;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  padding-bottom: 50px;
  margin-bottom: 50px;
  overflow-y: scroll;
  display: flex;
}

.createProjectFormPage {
  text-align: left;
  background-image: url(./pictures/createProjectFormPage.jpg);
  position: relative;
  min-width: 100%;
  width:auto;
  height:100vh;
  flex-direction: column;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  padding-bottom: 50px;
  margin-bottom: 50px;
  overflow-y: scroll;
  display: flex;
}

.openProjectFormPage {
  text-align: left;
  background-image: url(./pictures/openProjectFormPage.jpg);
  position: relative;
  min-width: 100%;
  width:auto;
  height:100vh;
  flex-direction: column;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  padding-bottom: 50px;
  margin-bottom: 50px;
  overflow-y: scroll;
  display: flex;
}

.projectDashboard {
  text-align: left;
  background-image: url(./pictures/projectDashboard.jpg);
  position: relative;
  min-width: 100%;
  width:auto;
  height:45vh;
  flex-direction: column;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  padding-bottom: 50px;
  margin-bottom: 50px;
  /* overflow-y: scroll; */
  display: flex;
}

.inputSections {
  text-align: left;
  background-image: url(./pictures/blueBar.jpg);
  position: relative;
  min-width: 100%;
  width:auto;
  height:37vh;
  flex-direction: column;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  padding-bottom: 50px;
  margin-bottom: 50px;
  /* overflow-y: scroll; */
  display: flex;
}

.userDashboard {
  text-align: left;
  background-image: url(./pictures/userDashboard.jpg);
  position: relative;
  min-width: 100%;
  width:auto;
  height:45vh;
  flex-direction: column;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  padding-bottom: 50px;
  margin-bottom: 50px;
  /* overflow-y: scroll; */
  display: flex;
}



.userLoginPage {
  text-align: left;
  background-image: url(./pictures/openProjectFormPage.jpg);
  position: relative;
  min-width: 100%;
  width:auto;
  height:100vh;
  flex-direction: column;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  padding-bottom: 50px;
  margin-bottom: 50px;
  overflow-y: scroll;
  display: flex;
}

.userSignup {
  text-align: left;
  background-image: url(./pictures/openProjectFormPage.jpg);
  position: relative;
  min-width: 100%;
  width:auto;
  height:100vh;
  flex-direction: column;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  padding-bottom: 50px;
  margin-bottom: 50px;
  overflow-y: scroll;
  display: flex;
}


.displayResults {
  text-align: left;
  background-image: url(./pictures/displayResults.jpg);
  position: relative;
  min-width: 100%;
  width:auto;
  height:40vh;
  flex-direction: column;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  padding-bottom: 50px;
  margin-bottom: 50px;
  /* overflow-y: scroll; */
  display: flex;
}

.fieldSevenInfo {
  text-align: left;
  background-image: url(./pictures/fieldSevenInfo.jpg);
  position: relative;
  min-width: 100%;
  width:50vh;
  height:30vh;
  flex-direction: column;
  background-size: 60vh 30vh ;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 50px;
  margin-bottom: 50px;
  display: flex;
}

.fieldTwelveInfo {
  text-align: left;
  background-image: url(./pictures/fieldTwelveInfo.jpg);
  position: relative;
  min-width: 100%;
  width:70vh;
  height:50vh;
  flex-direction: column;
  background-size: 80vh 40vh ;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 50px;
  margin-bottom: 50px;
  display: flex;
}

.fieldFifteenInfo {
  text-align: left;
  background-image: url(./pictures/fieldFifteenInfo.jpg);
  position: relative;
  min-width: 100%;
  width:90vh;
  height:30vh;
  flex-direction: column;
  background-size: 100vh 40vh ;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 50px;
  margin-bottom: 50px;
  display: flex;
}

.adminDashboard {
  text-align: left;
  /* background-image: url(./pictures/userDashboard.jpg); */
  position: relative;
  min-width: 100%;
  width:auto;
  height:20vh;
  flex-direction: column;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  /* padding-bottom: 50px; */
  /* margin-bottom: 50px; */
  /* overflow-y: scroll; */
  display: flex;
}

.helpPage {
  /* text-align: left; */
  background-color: #b0becf;
  position: relative;
  min-width: 100%;
  width:auto;
  height:100vh;
  flex-direction: column;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  padding-bottom: 50px;
  margin-bottom: 50px;
  /* overflow-y: scroll; */
  display: flex;
}


.customTab {
  text-align: left;
  /* position: relative; */
  /* min-width: 100%; */
  width:auto;
  height:5vh;
  flex-direction: column;
  background-size: cover;
  /* padding-bottom: 50px; */
  margin-top: 5vh;
  /* overflow-y: scroll; */
  display: flex;
}