.ui.color1.button {
    background-color: #0045A8;
    color: #fff;
    width: 160px
  }

  /* The one below is specifically for the project dashboard */
  .ui.next.button {
    background-color: #0045A8;
    color: #fff;
  }


  .main {
    width:100%;
    min-height:calc(100% - 100px);
    background-image: url('./pictures/landingPage.jpg');;
    background-size: cover;
    background-attachment:fixed;
    /* backgroundRepeat: 'no-repeat' */
  }